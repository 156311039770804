<template>
  <v-container class="content">
    <v-row>
      <v-col align="center">
        <h1 class="mb-8">Create Store</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Info</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <store-form :store="store"></store-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="right">
        <v-btn
          right
          color="primary"
          dark
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          Create
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";
import StoreForm from "@/components/admin/StoreForm.vue";

export default {
  components: {StoreForm},
  name: "AdminStoreCreateView",
  metaInfo: {
    title: "Create Store",
  },
  data() {
    return {
      store: {},
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;

      axios
        .post("/api/admin/store", this.store)
        .then(() => {
          this.$store.dispatch('banner/open', {text: 'Created new Store!'})
          this.$router.push({name: 'adminStoresList'})
        })
        .catch((r) => {
          console.log("error", r)
          if (r.response.status === 400 && r.response.data.errors) {
            let errors = r.response.data.errors
            let message = errors[0].field + ' ' + errors[0].message
            this.$store.dispatch('banner/error', {text: message, dismissable: true})
          } else {
            this.$store.dispatch('banner/error', {text: 'There was an error during saving'})
          }

        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
